<template>
    <div>
        <p v-if="hintText">{{ $t(hintText) }}</p>
        <v-select
            v-model="selectedValue"
            :items="options"
            item-text="option"
            item-value="id"
            :label="$t('fields.drop_down_list.label')"
            :rules="isRequired ? [rules.required] : []"
            name="dropdown"
            :menu-props="menuProps"
            @change="updateAnswer"
        >
            <template v-slot:item="data">
                {{ getLocalizedValue(data.item.option) }}
            </template>
            <template v-slot:selection="data">
                {{ getLocalizedValue(data.item.option) }}
            </template>
        </v-select>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'DropdownList',
    mixins: [
        GetLocalizedValue
    ],
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        hintText: {
            default: 'fields.drop_down_list.hint_text'
        },
        isRequired: {
            default: true
        },
        questionId: {}
    },
    data() {
        return {
            selectedValue: null,
            rules: {
                required: v => !!v || this.$t('fields.general.rules.required')
            }
        };
    },
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers']),
        menuProps() {
            if (this.screen$.height < 480) {
                return {
                    closeOnClick: false,
                    closeOnContentClick: false,
                    disableKeys: true,
                    openOnClick: false,
                    // maxHeight: this.screen.height - 72 - 63 - 10,
                    fixed: true,
                    // top: true
                };
            }
            return {
                closeOnClick: false,
                closeOnContentClick: false,
                disableKeys: true,
                openOnClick: false,
                maxHeight: 304
            };
        }
    },
    methods: {
        updateAnswer() {
            this.$emit('updateAnswer', this.selectedValue);
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        this.selectedValue = answer[this.questionId];
                    }
                });
            }
        }
    },
    created() {
        this.checkForAnswer();
    },
    watch: {
        'screen.height': function(newVal) {
            this.tinyHeight = newVal < 480;
        }
    }
};
</script>
